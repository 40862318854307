import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import { getLoginUrl } from '../../utils/auth'
import Link from '../shared/link'
import BaseButton from '../shared/button'

const P = styled.p`
  margin: 0;
  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[3]};
  }
`

const Button = styled(withAttrs(BaseButton, { as: Link }))`
  min-width: ${p => p.theme.space[26]};
  margin-top: ${p => p.theme.space[6]};
`

const LOGIN_URL = getLoginUrl()

export const LoginCta = props => (
  <div {...props}>
    <P>Is this your form?</P>
    <P>Log in to make changes</P>
    <Button to={LOGIN_URL}>Log in</Button>
  </div>
)

export const SignUpCta = props => (
  <div {...props}>
    <P>Is this not your form?</P>
    <P>Create your online form!</P>
    <Button to="/start/">Sign up</Button>
  </div>
)
